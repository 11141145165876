body {
  margin: 0;
  /* font-size: 62.5%; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Rubik", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Comfortaa",'Crimson Pro',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Funciona en Firefox */
* {
  /* scrollbar-width: 5px; */
  scrollbar-color: #2CA5C1 transparent;
}

/* Funciona en Chrome, Edge, y Safari */
/* [id^='scrollable']::-webkit-scrollbar { 
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

[id^='scrollable']::-webkit-scrollbar-button:increment,[id^='scrollable']::-webkit-scrollbar-button {
  display: none;
} 

[id^='scrollable']::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

[id^='scrollable']:hover::-webkit-scrollbar-thumb {
  border: 1px solid #2888AA;
  background-color: #2CA5C1;
}

[id^='scrollable']::-webkit-scrollbar-thumb:hover {
  background-color: #2888AA;
}

[id^='scrollable']::-webkit-scrollbar-track {
  border-radius: 10px;  
} */

*::-webkit-scrollbar { 
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
  display: inline-block;
} 

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

*:hover::-webkit-scrollbar-thumb {
  /* border-top: 6px solid #2888AA;
  border-bottom: 6px solid #2888AA;
  border-right: 1px solid #2888AA;
  border-left: 1px solid #2888AA; */
  border: 2px solid transparent;
  /* border-right: 0px; */
  background-color: #2CA5C1;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2888AA;
  background-clip: border-box;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;  
}